import React from 'react';
import { graphql, Link } from 'gatsby';
import { Helmet, useTranslation } from 'gatsby-plugin-react-i18next';
import { useLocation } from '@reach/router';
import { BasicLayout } from '../components';

const NotFound: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <BasicLayout>
      <Helmet>
        <meta charSet={'utf-8'} />
        <title>{`${t('name')} - ${t('slogan')} | ${t(
          'not-found.title',
        )}`}</title>
        <meta name={'description'} content={t('description')} />
        <meta name={'keywords'} content={t('keywords')} />
        <meta
          name={'viewport'}
          content={'width=device-width, initial-scale=1.0'}
        />
        <meta property={'og:title'} content={`${t('name')} - ${t('slogan')}`} />
        <meta property={'og:description'} content={t('description')} />
        <meta property={'og:type'} content={'website'} />
        <meta property={'og:url'} content={location.origin} />
        <meta property={'og:image'} content={'/image.png'} />
        <link rel={'icon'} type={'image/svg+xml'} href={'favicon.svg'} />
      </Helmet>
      <div className={'not-found'}>
        <div className={'content'}>
          <h1>{t('not-found.title')}</h1>
          <Link to={'/'}>{t('not-found.description')}</Link>
        </div>
      </div>
    </BasicLayout>
  );
};

export default NotFound;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
